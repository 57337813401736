.financing-profiling-profiles__limits {
    font-weight: bold;
}

.financing-profiling-profiles__add {
    display: inline-block;
    cursor: pointer;
    font-size: 120%;
}

.financing-profiling-profiles__profile {
    margin-bottom: 35px;
}

.financing-profiling-profiles__modal-title {
    font-size: 22px;
    font-weight: bold;
}

.notice-import-file-row {

}

.notice-import-file-row .table-component__cell {
    padding-right: 5px;
}

.notice-import-file-row__import {
    margin: 5px;
    font-size: 18px;
}

.email-subscriptions-upload {
    margin: 20px 0;
}

.email-subscriptions-upload__header {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
    font-size: 24px;
}

.email-subscriptions-upload__icon {
    transition: transform 300ms ease-in-out;
    font-weight: bold;
    margin-left: auto;
}

.email-subscriptions-upload__icon--collapsed {
    transform: rotateZ(-90deg);
}

.email-subscriptions-upload__body {
    padding: 30px 0;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.email-subscriptions-upload__column {
    width: 48%;
}

.email-subscriptions-upload__footer {
    margin-top: 20px;
    width: 100%;
}

.email-subscriptions-upload .row {
    display: flex;
    align-items: center;
}

.email-subscriptions-upload .select-component .Select-value {
    max-width: 270px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.email-subscriptions-upload .VirtualizedSelectOption {
    white-space: nowrap;
}

@media (max-width: 1200px) {
    .email-subscriptions-upload__column {
        font-size: 16px;
    }
    .email-subscriptions-upload .select-component .Select-value {
        max-width: 210px;
    }
}

.email-subscriptions-upload-info-modal {
    display: flex;
    align-items: center;
}
.info-icon {
    font-size: 25px;
    margin-left: 10px;
}
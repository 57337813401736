.ref-errors-results__pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.ref-errors-results__content {
    position: relative;
    font-size: 15px;
}

.workflow-tree-form-edit-node .workguide-component-id-map .collapsible-title {
  border: 0px;
  padding-left: 0px;
}

.workflow-tree-form-edit-node .workguide-component-id-map .collapsible-content {
  border: 0px;
  padding-left: 0px;
}

.workflow-tree-form-edit-node .workflow-tree-node-id-map .collapsible-title {
  border: 0px;
  padding-left: 0px;
}

.workflow-tree-form-edit-node .workflow-tree-node-id-map .collapsible-content {
  border: 0px;
  padding-left: 0px;
}

.workflow-tree-form-edit-node .workguide-jmes-input-help .collapsible-title {
  border: 0px;
  padding-left: 0px;
}

.workflow-tree-form-edit-node .workguide-jmes-input-help .collapsible-content {
  border: 0px;
  padding-left: 0px;
}

.workflow-tree-form-edit-node .collapsible-title {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 1em;
  padding-right: 1em;
  font-weight: bold;
  font-size: 1.1em;
  border: 1px solid lightgray;
  border-bottom: 0px;
  border-radius: 2px;
}

.workflow-tree-form-edit-node .control-label {
  width: auto;
}

.workflow-tree-form-edit-node .collapsible-title--expanded {
  border-bottom: 1px solid lightgray;
}

.workflow-tree-form-edit-node {
  .workflow-tree-form-edit-node--collapsible:nth-last-of-type(2) {
    .collapsible-title {
        border-bottom: 1px solid lightgray;
      }
  }
}

.workflow-tree-form-edit-node .workflow-tree-form-edit-node--collapsible--invalid {
  > .collapsible-title {
    background-color: rgba(255, 0, 0, 0.1);
  }
}

.workflow-tree-form-edit-node .collapsible-content {
  padding-left: 2em;
}

.workflow-tree-form-edit-node--general--assigneeId-options {
  display: flex;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
}

.workflow-tree-form-edit-node--general--completionDate-options {
  display: flex;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
}

.workflow-tree-form-edit-node--buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 2em;
  text-align: right;
}

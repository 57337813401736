.pagination-component.pagination {
    display: flex;
}
.pagination-component.pagination li {
    margin-right: 5px;
}
.pagination-component.pagination li:last-child {
    margin-right: 0;
}
.pagination-component.pagination li a {
    float: none;
    border: none;
    width: 45px;
    height: 45px;
    padding: 10px 0;
    display: block;
    text-align: center;
    border-radius: 4px;
}
.pagination-component.pagination li.active a {
    background-color: #1781e3;
    color: #fff;
}
.pagination-component__control {
    font-size: 26px;
}

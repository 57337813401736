.financing-profiling__table {
    margin-top: 20px;
}

.financing-profiling__row {
    display: flex;
    border-bottom: 1px solid #bbb;
}

.financing-profiling__row:last-child {
    border-bottom: none;
}

.financing-profiling__row--header {
    font-weight: bold;
}

.financing-profiling__cell {
    padding: 10px;
    width: 30%;
    border-right: 1px solid #bbb;
}

.financing-profiling__cell:last-child {
    border-right: none;
}

.financing-profiling__cell--title {
    font-weight: bold;
    width: 170px;
}

.param-transfer-source-items {
  padding-bottom: 2em;
  padding-left: 2em;
}

.param-transfer-source-items--loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
}

.param-transfer-source-items--header-row {
  font-weight: 700;
  border-bottom: 1px solid #474b4e;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  color: #474b4e;
  padding-right: 1.5em;
}

.param-transfer-source-items--search-row {
  display: flex;
  align-items: center;
}

.param-transfer-source-items--search-row--col-search-term .mdi {
  font-size: 1.2em;
}


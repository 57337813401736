.page-container {
    background: #fff;
    box-shadow: -1px 1px 8px -4px #ddd;
    padding: 20px 50px 40px 50px;
    margin-top: 37px;
    font-size: 18px;
}
.page-container__header {
    display: flex;
}
.page-container__header-title {
    font-size: 40px;
    margin-top: 10px;
    flex-grow: 1;
}
.page-container__header-action {
    margin-left: auto;
    font-size: 40px;
    line-height: 32px;
    text-align: right;
   
}
.page-container__header-action a {
    text-decoration: none;
    font-weight: 100;
}
.page-container__body {
    position: relative;
}
.page-container__footer {
    margin-top: 110px;
    display: flex;
}
.page-container__footer-actions {
    margin-left: auto;
}
.page-container__footer-actions .btn {
    margin-left: 20px;
}

.page-container__spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

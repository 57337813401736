.api-client-headers-form__row {
    display: flex;
    align-items: center;
}
.api-client-headers-form__cell {
    flex-grow: 1;
    margin: 10px 15px 10px 0;
}
.api-client-headers-form__cell .form-group {
    margin-bottom: 0;
}
.api-client-headers-form__action {
    font-size: 22px;
}
.api-client-headers-form__button {
    cursor: pointer;
    font-size: 22px;
}

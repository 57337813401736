.workguide-action--item {
  display: flex;
  align-items: center;
  width: 100%;
}

.workguide-action--item-content {
  width: 100%;
}

.workguide-action--item-draghandle {
  width: 40px;
  height: 100%;
}

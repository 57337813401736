.search-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-right: 30px;
    height: 47px;
}
.search-block .form-control {
    font-size: 18px;
    padding: 10px 40px 10px 10px;
    height: auto;
    width: 400px;
}
.search-block__icon {
    position: absolute;
    right: 9px;
    top: 8px;
    font-size: 31px;
}

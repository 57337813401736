.analytics-logs-results-comparison__label {
    width: 150px;
    font-weight: bold;
}

.analytics-logs-results-comparison__value {
    max-width: 350px;
    word-wrap: break-word;
}

.analytics-logs-results-comparison__pre pre.diff-result {
    white-space: pre;
    max-width: 700px;
    min-height: 300px;
    max-height: 600px;
    background: #dde3e7;
    margin: 10px 0;
}

.analytics-logs-results-comparison__response-code {
    border-bottom: 1px dotted;
    cursor: help;
}

.ui-notes-image-field {
}
.ui-notes-image-field__dropzone {
    border: dashed 1px #000;
}

.ui-notes-image-field__preview {
    display: flex;
    justify-content: space-between;
}

.ui-notes-image-field__preview-image img {
    width: 150px;
    height: auto;
}

.ui-notes-image-field__preview-actions {
    display: flex;
    flex-direction: column;
}

.ui-notes-image-field__preview-actions a {
    cursor: pointer;
    font-size: 0.9em;
}

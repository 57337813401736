.bulk-loads-quality--open {
    color: #888888;
}
.bulk-loads-quality--working {
    color: #008bc8;
}
.bulk-loads-quality--done {
    color: #14ad19;
}
.bulk-loads-quality--error {
    color: #be242d;
}

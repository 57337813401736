.react-login--login-container {
  padding-top: 5rem;
  padding-right: 25%;
  padding-left: 25%;
  text-align: center;
}

.react-login--gateway-login--form--right-col {
  padding-top: 2rem;
}

.react-login--gateway-login--form--input-group {
  display: flex;
  align-items: center;
}

.react-login--gateway-login--form--input-group .input-group-text {
  padding-right: 2rem;
}

.checklist-header {
    margin-top: 30px;
    font-size: 24px;
    color: #686b6f;
    display: flex;
}
.checklist-header__text {
    width: 300px;
}
.checklist-header label {
    font-size: 18px;
    font-weight: normal;
    display: flex;
    align-items: center;
}
.checklist-header__control {
    margin-left: auto;
}

.core-config-menu {
    display: flex;
    margin-top: 30px;
}
.core-config-menu-item {
    padding: 6px 14px;
    color: #888;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    margin-right: 20px;
}
.core-config-menu-item--active {
    color: #888;
    border-color: #008bc8;
}

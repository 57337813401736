.checklist-item.SortableHOCItem {
    display: block;
    margin-bottom: 12px;
    height: 48px;
}

.checklist-item.SortableHOCItem .input-group-addon {
    background: none;
    font-size: 22px;
    border-radius: 6px 0 0 6px;
}
.checklist-item__checkbox {
    padding-top: 2px;
}

.checklist-item .form-control {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    height: auto;
    border-left: none;
    border-right: none;
}
.checklist-item .SortableHOCHandle {
    margin: 0;
    font-size: 26px;
}
.checklist-item.SortableHOCItem .input-group-addon.checklist-item__control {
    border-left: none;
    border-radius: 0;
}
.checklist-item.SortableHOCItem .input-group-addon.checklist-item__drag {
    border-radius: 0 6px 6px 0;
}

.api-client-response__label {
    width: 100px;
    font-weight: bold;
}

.api-client-response__value {
    word-wrap: break-word;
}

.api-client-response__response-code {
    border-bottom: 1px dotted;
    cursor: help;
}

.api-client-response .react-json-view {
    font-size: 14px;
}

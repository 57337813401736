.config-panel {
    margin-top: 30px;
}
.config-panel__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d6d6d6;
    padding: 10px;
    font-weight: bold;
}
.config-panel__title {
    min-width: 45%;
    max-width: 45%;
}
.config-panel__value {}

.email-subscriptions-export--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  font-size: 24px;
  padding-top: 10px;
  cursor: pointer;
}

.email-subscriptions-export--title--icon {
  font-size: 1.4em;
  font-weight: normal;
}

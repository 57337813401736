:root {
  --bs-primary: #0851da;
  --bs-success: #9baa00;
  --bs-danger: #f22d54;
  --bs-warning: #f3a91b;
}

td,th {
  padding-left: 10px;
}

td:last-child {
  padding-right: 10px;
}

tr {
  border-bottom: 1px solid gray;
}

.label--invalid {
  color: var(--bs-danger);
}

/* --------------------------------------
   Missing helpers in bootstrap 3
   -------------------------------------- */
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.pointer {
  cursor: pointer;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

/* --------------------------------------
   @evoja-web/react-form
   -------------------------------------- */
.react-form-validation-result-form-text {
  color: var(--bs-danger);
}

/* --------------------------------------
   @evoja-web/react-login
   -------------------------------------- */
.react-login--gateway-login--form--button-container .btn {
  width: 100%;
}

/* --------------------------------------
   Markdown Area
   -------------------------------------- */
.markdown-modal {
  width: 80%;
}

.markdown-input {
  width: 500px;
  float: left;
}

.markdown-preview {
  margin-left: 530px;
  padding: 10px;
  overflow: auto;
  height: 570px;
  border: 1px solid gray;
}

.navbar-brand {
  padding: 5px; /* firefox bug fix */
}
.navbar-brand>img {
  height: 100%;
  padding: 3px; /* firefox bug fix */
  width: auto;
  top: 0;
}


/* --------------------------------------
   Layout
   -------------------------------------- */

.start-page__modules {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
}
.start-page__menu {
  width: 100%;
  margin-bottom: 20px;
}

#inputMessage {
	margin-bottom: 15px;
}

footer {
	background: #eee;
	font-size: 11px;
	padding: 20px;
}

/* --------------------------------------
   Sortable HOC
   -------------------------------------- */
   .SortableHOCHandle {
   position:relative;
   top:2px;
   display:inline;
   margin-right:10px;
   cursor: move;
   font-size: 20px;
 }

 .SortableHOCList {
   width:100%;
   height:100%;
   overflow:auto;
   -webkit-overflow-scrolling:touch;
   position:relative;
   z-index:0;
   background-color:#f3f3f3;
   border:1px solid #efefef;
   border-radius:3px;
   outline:none;
 }

.SortableHOCItem {
  position:relative;
  display:-webkit-box;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -webkit-align-items:center;
  -ms-flex-align:center;
  align-items:center;
  width:100%;
  background-color:#fff;
  box-sizing:border-box;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  color:#333;
  font-weight:400;
}

.SortableHOCHelper {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.2), 0 -10px 10px -10px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.8);
    cursor: row-resize;
}

/* --------------------------------------
   List style
   -------------------------------------- */

ul.unstyled {
    list-style: none;
    padding: 0;
}

li.unstyled {
  list-style: none;
}

li.draggable {
    margin-bottom: .5rem;
    background-color: white;
}

/* --------------------------------------
   Form
   -------------------------------------- */

.input-wrapper {
    position: relative;
    height: 34px
}

.input-wrapper .input-label {
    position: absolute;
    top: 6px;
    left: 15px;
    z-index: 10;
}

.remove-btn {
  font-size: 15px;
  position: relative;
  bottom: 4px;
  left: 8px;
  color: white;
  background-color: #c5c5c5;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.remove-btn span {
  margin-left: 3px;
}

.form-row .form-control-feedback {
  right: 15px;
}

/* Date Picker from @evoja-web/react-form */
.react-datepicker-wrapper .input-group {
  display: flex;
  align-items: center;
}

.react-datepicker-wrapper .input-group .react-form-datefield-icon {
  font-size: 1.4em;
}

.react-datepicker-wrapper .input-group .react-form-datefield-icon .mdi-calendar {
  font-size: 1em;
  padding: 0.2em;
  padding-bottom: 0.3em;
  border: 1px solid #d6d6d6;
  border-left: 0px;
  vertical-align: middle;
}

.react-datepicker-wrapper .input-group .react-form-datefield-icon .mdi::before {
  vertical-align: middle;
}

.react-datepicker {
  font-size: 1em !important;
}

.react-datepicker__header {
  padding-top: 0.8em !important;
}

.react-datepicker__month {
  margin: 0.4em 1em !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
}

.react-datepicker__current-month {
  font-size: 1em !important;
}

.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
  left: 1em !important;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
  right: 1em !important;
}

.react-select-inner__menu {
  z-index: 7 !important;
}

  /* --------------------------------------
     Typography
     -------------------------------------- */

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizelegibility;
}

.well .page-header {
	margin-top: 0;
}

.well .page-header h1 {
	margin-top: 0;
}

i {
	margin-left: 5px;
}

a {
	color: #2c3e50;
}


/* --------------------------------------
   Global Styles
   -------------------------------------- */

.well {
	margin: 20px 0;
}

.tabbable img.thumbnail {
	margin: 0 20px 10px 0;
}

.thumbnails {
	margin: 20px 0;
}

.thumbnail {
	position: relative;
}

.thumbnail h3 {
	font-size: 16px;
	margin: 0 0 10px;
	line-height: 20px;
}

.thumbnail .price {
	font-size: 22px;
	position: absolute;
	top: -10px;
	right: -10px;
	padding: 10px;
}

.thumbnail .price sup {
	font-weight: normal;
	font-size: 12px;
}

.thumbnail span.glyphicon {
	margin-right: 5px;
}

.is-violated {
    border-color: red;
}

/* -----------------------------
  Toggle
-------------------------------*/

.react-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-left: 5px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle.toggle-agenda {
  vertical-align: text-bottom;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}

/* -----------------------------
  TextDiff
-------------------------------*/
pre.diff-result {
  word-break: break-word;
  white-space: pre-wrap;
  background-color: lightgray;
}

/* -----------------------------
  Table
-------------------------------*/

.table .dropdown {
  width: 100%;
}

.table .dropdown .btn {
  margin: 0 auto;
  float: none;
  display: block;
}

[class^="icon-"] + [class^="icon-"] {
  margin-left: 5px;
}



/* --------------------------------------
   Autosuggest
   --------------------------------------*/
   .react-autosuggest__input {
    width: 100%;
    font: 18px arial, sans-serif;
    cursor: auto;
    padding-right: 0px;
    margin: 0px;
    height: 40px;
    background: url("data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw%3D%3D") transparent;
    z-index: 6;
    left: 0px;
    outline: none;
    border:1px solid #eaeaea;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1);
    background: #f8f8f8;
  }

  .react-autosuggest__container {
    margin-top: 50px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .react-autosuggest__suggestions-container {
    width: 100%;
  }

  .react-autosuggest__suggestion {
    text-align: left;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #EEEEEE;
  }

  .react-autosuggest__suggestions-list {
    list-style: none;
    padding: 0px;
    text-align: left;
  }

  .react-autosuggest__input {
    padding-left: 10px;
  }


/* -----------------------------
  React PDF
-------------------------------*/

.ReactPDF__Document {
    border: 1px solid;
}

/* -----------------------------
  Helpers
-------------------------------*/
.above-the-clouds {
  z-index: 9999;
}

.full-width {
  width: 100%;
}

.react-anchor {
  color: #7152ac !important;
  text-decoration: none;
  cursor: pointer;
}

.react-anchor-inactive {
  color: #625f68 !important;
  text-decoration: none;
  cursor: pointer;
}

.react-anchor:hover, .react-anchor-inactive:hover {
  text-decoration: underline;
}

.list-group-item {
  position: relative;
  display: block;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
  cursor: pointer;
}

.powersearch-input-addon {
  background-repeat: no-repeat;
  background-position: center top;
  margin-left: 95%;
  margin-top: -3%;
  position: relative;
  height: 20px;
  z-index: 1;
}

.quickSearch {
  width: 100%;
  height: 30px;
  padding: .375rem .75rem;
  border: 1px solid #ced4da;
}

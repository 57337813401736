.api-client-login-form {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    position: relative;
}

.api-client-login-form__input {
    width: 300px;
    margin-right: 10px;
}

.api-client-login-form__spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.api-client-login-form__error {
    color: #dd0000;
}

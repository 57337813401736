.bank-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d6d6d6;
    padding: 10px 0;
}
.bank-item__action {
    text-align: right;
    cursor: pointer;
    margin-left: auto;
    font-size: 26px;
}
.bank-item__action .SortableHOCHandle {
    font-size: 32px;
    margin-right: 0;
    margin-left: 15px;
}
.bank-item__text {
    min-width: 53%;
    max-width: 53%;
}
.bank-item__text-short {
    min-width: 35%;
    max-width: 35%;
    margin-left: 20px;
}
.bank-item .form-control {
    font-size: 18px;
    padding: 10px;
    height: auto;
}

label {
  width: 250px;
  margin-top: 10px;
}

.select-input {
  margin-top: 20px;
  width: 240px;
  height: 34px;
  border: 1px solid #d6d6d6;
  border-radius: 5px 5px;
}

.textarea-input {
  margin-top: 20px;
  width: 480px;
  height: 60px;
  border: 1px solid #d6d6d6;
  border-radius: 5px 5px;
}
 
.checkbox-input {
  height: 20px;
  width: 20px;
  border: 1px solid #d6d6d6;
  border-radius: 5px 5px;
}

.text-input {
  margin-top: 20px;
  width: 240px;
  height: 34px;
  border: 1px solid #d6d6d6;
  border-radius: 5px 5px;
}

.addParameter {
  color: white;
  background-color: #1781e3;
}

.removeParameter {
  color: white;
  background-color: rgb(204, 54, 54);
}

.parameterLabel {
  font-size: medium;
}

.triggerButton {
  width: 150px;
  height: 50px;
  margin-top: 30px;
  border: 1px solid black;
  color: white;
  background-color: #1781e3;
}

.clearButton {
  width: 150px;
  height: 50px;
  margin-left: 50px;
  margin-top: 30px;
  border: 1px solid black;
  background-color: #ffffff;
}
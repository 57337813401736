.calendar-month {
}
.calendar-month__content {
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(7, 1fr);
    box-sizing: border-box;
}
.calendar-month__day {
    min-height: 100px;
    border: 1px solid #eee;
    margin-top: -1px;
    margin-left: -1px;
}
.calendar-month__week {
    display: flex;
    margin-bottom: 5px;
}
.calendar-month__week-day {
    width: 14%;
    flex-grow: 1;
    color: #888;
}

.api-client-service-info__section {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
}

.api-client-service-info__section:last-child {
    margin-bottom: 0;
}

.api-client-service-info__label {
    font-weight: bold;
    margin-bottom: 10px;
}

.api-client-service-info__body {
    margin-bottom: 0;
}

.api-client-service-info__item {
    padding-left: 20px;
}

.api-client-service-info .react-json-view {
    font-size: 14px;
}

.table-component {
  display: table;
  overflow-x: auto;
  border-collapse: collapse;
  width: 100%;
}
.table-component__row,
.table-component__head {
  display: table-row;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
}
.table-component__row:last-child {
  border-bottom: none;
}
.table-component__head {
  font-weight: bold;
}
.table-component__cell {
  display: table-cell;
  padding: 2px 5px;
  height: 40px;
  vertical-align: middle;
  min-width: 120px;
  max-width: 50%;
  word-wrap: break-word;
}

.analytics-active-users {
    margin-top: 30px;
}
.analytics-active-users__table {
    margin-top: 30px;
}
.analytics-active-users__sortable {
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
}

.api-client-pdf-preview__content {
    width: 750px;
    text-align: center;
}

.api-client-pdf-preview__pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.api-client-pdf-preview__error {
    font-size: 200%;
    text-align: center;
    margin-top: 10px;
}

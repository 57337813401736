.financing-profiling-edit {
}

.financing-profiling-edit__label {
    width: 40%;
    font-weight: bold;
}

.financing-profiling-edit__value {
    padding-right: 0!important;
    width: 60%;
}

.financing-profiling-edit__limit {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.financing-profiling-edit__limit .input-component {
    width: 140px;
    display: inline-block;
}

.financing-profiling-edit__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.financing-profiling-edit__save {
    margin-right: 10px;
}

.financing-profiling-edit__invalid,
.financing-profiling-edit__invalid .Select-control {
    border-color: red;
}

.financing-profiling-edit__durations {
    margin-top: 20px;
}

.financing-profiling-edit__durations-head {
    background: #f7f7f7;
}

.financing-profiling-edit__durations .table-component__cell {
    padding: 2px 10px;
}

.workguide-form-field-date-configuration-flex--template {
  display: flex;
  align-items: center;
}

.workguide-form-field-date-configuration-flex--col {
  display: flex;
  align-items: center;
}

.workguide-form-field-date-configuration-flex--col--select {
  width: 80%;
  min-width: 80%;
}

.workguide-form-field-date-configuration-flex--col--number {
  padding-left: 0.4em;
}

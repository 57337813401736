.analytics-menu {
    display: flex;
    margin-top: 30px;
}
.analytics-menu__item {
    padding: 6px 14px;
    color: #888;
    border-bottom: 2px solid transparent;
    margin-right: 20px;
}
.analytics-menu__item--active {
    color: #888;
    border-color: #008bc8;
}

.analytics-menu__item a {
    color: #888;
    text-decoration: none;
}
.analytics-menu__item--active a {
    color: #888;
}

.holidays-calendar-month {
    line-height: 28px;
    cursor: pointer;
}
.holidays-calendar-month__day {
    line-height: 17px;
    margin: 10px 0;
}
.holidays-calendar-month__title {
    font-size: 16px;
    font-weight: bold;
    padding: 5px 15px;
    background: #f8f8f8;
}
.holidays-calendar-month__content {
    padding: 8px 15px;
    min-height: 40px;
}
.holidays-calendar-month__number {
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
}

.code-form {
  background-color: #eeeeee;
  padding: 20px;
}

.validation-result {
  padding-top: 2px;
}

.validation-result-item {
  color: red;
  font-size: 11px;
}

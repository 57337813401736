.collapsible-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.collapsible-title--label {
  width: 100%;
}

.collapsible-title--exlapse {
  font-size: 2em;
}

.collapsible-content--expanded {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.4s linear;
}

.collapsible-content--collapsed {
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  height: 0;
}

.bulk-loads-results-item {
    border-bottom: none;
}

.bulk-loads-results-item .table-component__cell {
    padding: 2px 4px;
}

.bulk-loads-results-item__details {
    border-top: none;
    border-bottom: 1px solid #e5e5e5;
}

.bulk-loads-results-item__details .table-component__cell {
    height: auto;
    padding: 0;
}

.bulk-loads-results-item__details-content {
    padding: 10px 100px;
}

.bulk-loads-results-item__details-table .table-component__row {
    border: none;
}

.bulk-loads-results-item__label {
    width: 200px;
    font-weight: bold;
}

.bulk-loads-results-item__state {
    position: relative;
    width: 50px;
}

.bulk-loads-results-item__id {
    min-width: 140px;
}

.bulk-loads-results-item__open {
    min-width: 100px;
}

.bulk-loads-results-item__done {
    min-width: 100px;
}

.bulk-loads-results-item__count {
    min-width: 70px;
}

.bulk-loads-results-item__time {
    min-width: 150px;
}

.bulk-loads-results-item__quality {
    min-width: 90px;
}

.bulk-loads-results-item__icon {
    cursor: pointer;
    transition: transform 200ms ease-in-out;
    font-weight: bold;
    margin-left: auto;
    width: 16px;
}

.bulk-loads-results-item__icon--expanded {
    transform: rotateZ(90deg);
}

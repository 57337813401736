.ref-errors-results-item .table-component__cell {
    padding: 2px 4px;
}

.ref-errors-results-item__id {
    min-width: 140px;
}

.ref-errors-results-item__origin {
    min-width: 300px;
}

.ref-errors-results-item__target {
    min-width: 300px;
}

.ref-errors-results-item__target-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
}

.ref-errors-results-item__icon {
    cursor: pointer;
    font-weight: bold;
    margin-left: auto;
    width: 16px;
}

.ref-errors-results-item__link:hover {
    text-decoration: none;
}

.analytics-date-range {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #e5e5e5;
}
.analytics-date-range__button {
    margin-left: 10px;
}
.analytics-date-range__button.button-component.btn-sm {
    height: 46px;
}

.module-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d6d6d6;
    padding: 10px;
}
.module-item__action {
    text-align: right;
    cursor: pointer;
    margin-left: auto;
}
.module-item__action .SortableHOCHandle {
    font-size: 32px;
    margin-right: 0;
}
.module-item__title {
    min-width: 45%;
    max-width: 45%;
}
.module-item__value {
    width: 350px;
}
.module-item__value .form-control {
    font-size: 18px;
    padding: 10px;
    height: auto;
}

.bulk-loads-state-icon {
    font-size: 20px;
}
.bulk-loads-state-icon--open {
    color: #888888;
}
.bulk-loads-state-icon--working {
    color: #008bc8;
}
.bulk-loads-state-icon--done {
    color: #14ad19;
}
.bulk-loads-state-icon--error {
    color: #be242d;
}

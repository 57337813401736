.workflow-tree-node {
  position: absolute;
  z-index: 2;
}

.workflow-tree-node--invalid {
  .workflow-tree-node--content--details {
    background-color: rgba(255, 0, 0, 0.1);
  }
}

.workflow-tree-node--content {
  display: flex;
  align-items: center;
}

.workflow-tree-node--content--toolbar {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.4em;
  width: 90%;
  top: -70%;
  padding-left: 40%;
}

.workflow-tree-node--content--toolbar .mdi {
  cursor: pointer;
}

.workflow-tree-node--content--details {
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  padding-left: 2em;
  padding-right: 2em;
  background-color: #ffffff;
  border: 1px solid gray;
  border-radius: 0.1em;
}

.workflow-tree-node--content--attach-point {
  width: 1em;
  height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  border: 1px solid gray;
  cursor: pointer;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.workflow-tree-form-edit-node--actions--list {
  padding-bottom: 2em;
}

.workflow-tree-form-edit-node--actions--header .mdi {
  font-size: 1.4em;
}

.workflow-tree-form-edit-node--actions--action:last-of-type {
  .collapsible-title {
    border-bottom: 1px solid lightgray;
  }
}

.feature-flag-list {
  margin-top: 20px;
  border: none;
  background: transparent;
}
.feature-flag-list__header {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-weight: bold;
}
.feature-flag-list__text {
  width: 350px
}
.feature-flag-list__actions {
  margin-top: 20px;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
}

.holidays-calendar {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #eee;
}
.holidays-calendar__month {
    border: 1px solid #eee;
    display: table-cell;
    min-width: 25%;
    cursor: pointer;
}
.holidays-calendar__month-title {
    flex-wrap: wrap;
    font-weight: bold;
}
.holidays-calendar__day {
    border: 1px solid #eee;
    display: table-cell;
    width: 14%;
    max-width: 14%;
}

.job-processing-overview__container {
    display: flex;
    justify-content: space-between;
}

.job-processing-overview__btn {
    width: 49%;
    padding: 20px;
    background-color: white;
    border: 3px solid
}

.job-processing-overview__btn:focus, .job-processing-overview__btn:hover {
    background-color: white;
}

.green, .green:focus, .green:hover {
    color: green;
    border-color: green;
}

.red, .red:focus, .red:hover {
    color: red;
    border-color: red;
}
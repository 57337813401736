.module-overview--categories {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.4em;
}

.module-overview--categories--category {
  color: #777777;
  font-size: 1.2em;
  cursor: pointer;
  padding-bottom: 0.2em;
  border-bottom: 2px solid #f8fafb;
}

.module-overview--categories--category--active {
  border-bottom: 2px solid #008bc8;
}

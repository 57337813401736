.workflow-tree-form-condition--condition-generic {
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 2em;
}

.workflow-tree-form-condition--condition-generic:first-of-type {
  padding-top: 0em;
}

.workflow-tree-form-condition--condition-generic .mdi {
  font-size: 1.4em;
}

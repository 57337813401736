.workflow-tree-form-condition--condition-list {
  margin-top: 1em;
}

.workflow-tree-form-condition--condition-list--conditions {
  padding-left: 0.4em;
  border: 0px;
  border-left: 0.1em;
  border-color: gray;
  border-style: dashed;
}

.workflow-tree-form-condition--condition-list .workflow-tree-form-condition--condition-list {
  padding-left: 2em;
}

.workflow-tree-form-condition--condition-list--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.workflow-tree-form-condition--condition-list--header .mdi {
  font-size: 1.5em;
  cursor: pointer;
}

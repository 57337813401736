.lead-mass-mutation--section {
  padding-bottom: 3em;
}

.lead-mass-mutation--section-title {
  display: flex;
  align-items: center;
  font-size: 1.4em;
  cursor: pointer;
}

.lead-mass-mutation--section-title--exlapse {
  padding-left: 0.3em;
  font-size: 1.2em;
}

.lead-mass-mutation--section-content-collapsed {
  display: none;
  height: 0px;
}

.holidays-list-day {
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
}
.holidays-list-day__date {
    flex: 0 0 100px;
    text-align: right;
    white-space: nowrap;
}
.holidays-list-day__title {
    margin-left: 15px;
    flex-grow: 1;
}
.holidays-list-day__controls {
    margin-left: 10px;
}

.pagination-no-total-count {
  position: relative;
  width: fit-content;
}

.pagination-no-total-count--requesting {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 7;
  margin-top: -4px;
}
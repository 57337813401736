.set-management-bundle {
    margin-top: 20px;
}

.set-management-bundle__title {
    font-size: 120%;
}

.set-management-bundle__domain {
    margin-top: 20px;
}

.set-management-bundle__domain-select {
    width: 300px;
}

.set-management-bundle__list {
    margin-top: 20px;
}

.set-management-bundle__item-name {
    width: 20%;
}

.set-management-bundle__item-domain {
    width: 30%;
}

.set-management-bundle__item-bundle-price {
    width: 15%;
}

.set-management-bundle__item-bundle-price-reduced {
    width: 15%;
}
.set-management-bundle__item-bundle-price-youth {
    width: 25%;
}

.tabs {
    display: flex;
    font-size: 18px;
}
.tabs__item {
    padding: 6px 14px;
    color: #777;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    margin-right: 20px;
}
.tabs__item--active {
    color: #777;
    border-color: #008bc8;
}

.bulk-loads-refresh {
    text-align: right;
    font-weight: normal;
    min-width: 50px;
}

.bulk-loads-refresh__button {
    cursor: pointer;
    display: inline-block;
    margin-left: 3px;
}

.bulk-loads-refresh__button--active {
    animation: spinAnimation 1s ease infinite;
}

@keyframes spinAnimation {
    from { transform: rotateZ(0deg); }
    to { transform: rotateZ(360deg); }
}

.workguide-form-field-date-configuration-flex--select {
  display: flex;
  align-items: center;
  width: 100%;
}

.workguide-form-field-date-configuration-flex--select--dropdown {
  width: 100%;
}

.workguide-form-field-date-configuration-flex--select--number {
  max-width: 15%;
  padding-left: 0.4em;
}

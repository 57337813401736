.bank-list {
    margin-top: 30px;
}
.bank-list__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d6d6d6;
    padding: 10px;
    font-weight: bold;
}
.bank-list__text {
    min-width: 53%;
    max-width: 53%;
    margin-right: 20px;
}
.bank-list__actions {
    margin-top: 20px;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
}

.month-selector {
    margin: 30px 0;
    display: flex;
}

.month-selector__item {
    padding: 10px;
    cursor: pointer;
    user-select: none;
    width: 155px;
    text-align: center;
}

.month-selector__item:hover {
    color: #008bc8;
}

.month-selector__item.month-selector__item--disabled {
    color: #aaa;
    cursor: default;
    pointer-events: none;
}

.month-selector__item.month-selector__item--active {
    color: #008bc8;
    cursor: default;
    pointer-events: none;
    background: #f5f5f5;
    border-radius: 6px;
}

.month-selector__arrow {
    width: 30px;
}

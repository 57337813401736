.param-transfer-target--login .react-form-validatable-messages {
  color: red;
}

.param-transfer-target--login--submit {
  width: 100%;
}

.param-transfer-target--login--error {
  margin-top: 2em;
  text-align: center;
}

.datetime-picker {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
}

.datetime-picker__date {
    flex-grow: 1;
    margin-right: 20px;
}

.datetime-picker__colon {
    padding: 0 3px;
}

.datetime-picker__hours,
.datetime-picker__minutes {
    width: 70px;
}

.datetime-picker__hours .form-control,
.datetime-picker__minutes .form-control {
    text-align: right;
    padding-right: 12px;
}

.has-error .datetime-picker .SingleDatePickerInput__withBorder {
    border-color: #d12;
}

.external-link-item.SortableHOCItem {
    display: flex;
    margin-bottom: 12px;
    border: 1px solid #d6d6d6;
    border-radius: 6px;
}

.external-link-item__text {
    font-size: 18px;
    padding: 10px 0;
    height: 47px;
    display: flex;
}

.external-link-item__name,
.external-link-item__link {
    text-overflow: ellipsis;
    overflow: hidden;
}
.external-link-item__name {
    width: 350px;
    padding-right: 20px;
}
.external-link-item__link {
    width: 545px;
}

.external-link-item .SortableHOCHandle {
    margin: 0;
    font-size: 26px;
}

.external-link-item__control {
    margin-left: auto;
}

.external-link-item__control span {
    cursor: pointer;
    font-size: 22px;
}

.external-link-item__drag {
    margin-left: 10px;
    padding: 10px;
    border-left: 1px solid #d6d6d6;
}

.external-link-item__favicon {
    padding: 0 10px;
}

.external-link-item__modal-title {
    font-size: 22px;
    font-weight: bold;
}

.external-link-item.external-link-item--invalid {
    border-color: #dd0000;
}

.feature-flag-item {
  margin-bottom: 12px;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
}

.feature-flag-item--sub-div {
  display: flex;
}

.feature-flag-item__text {
  font-size: 18px;
  padding: 10px 0;
  height: 47px;
  display: flex;
}

.feature-flag-item__collaps {
  font-size: 18px;
  padding: 10px 0;
  display: flex;
}

.feature-flag-item__name {
  width: 350px;
  padding-right: 20px;
}

.feature-flag-item {
  margin: 0;
  font-size: 26px;
}

.feature-flag-item__control {
  margin-left: auto;
  align-items: center;
  display: flex;
}

.feature-flag-item__control span {
  cursor: pointer;
  font-size: 22px;
}

.feature-flag-item__modal-title {
  font-size: 22px;
  font-weight: bold;
}

.list-group-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

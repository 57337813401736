.leads-switch-import {
}

.leads-switch-import__description {
    padding-bottom: 20px;
}

.leads-switch-import__upload {
    width: 140px;
}

.leads-switch-import__validation {
    width: 100px;
}

.leads-switch-import__button {
    font-size: 18px;
    margin-left: 15px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
p{
  margin: 0px 5px 5px 5px;
  word-break: break-all;
}

.card {
  width: 45%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 4px 3px 15px 1px gray;
  margin: 20px;
  border-radius: 15px;
  padding: 10px;
}

.card-title{
  width: 80%;
  font-size: 22px;
  font-weight: bold;
}

.row-one {
  display: flex;
  justify-content: space-between;
}

.row-three {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.card-label {
  font-size: 14px;
  font-weight: bold;
}

.action{
  padding: 5px;
  width: 60px;
  text-align: center;
  border-radius: 15px;
  color: white;
}

.detail-icon {
  cursor: pointer;
  color: gray;
  position: absolute;
  bottom: 8px;
  right: 16px;
  font-size: 28px;
}

.modal-row {
  display: flex;
  flex-wrap: wrap;
}
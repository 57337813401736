.config-panel-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d6d6d6;
    padding: 10px;
}
.config-panel-item__action {
    text-align: right;
    cursor: pointer;
    font-size: 26px;
    margin-left: auto;
}
.config-panel-item__action--disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
}

.config-panel-item__title {
    min-width: 45%;
    max-width: 45%;
}
.config-panel-item__value {
    width: 350px;
}
.config-panel-item__value .form-control {
    font-size: 18px;
    padding: 10px;
    height: auto;
}
.config-panel-item__value .Select-control {
    padding-top: 5px;
    padding-bottom: 5px;
}
.config-panel-item__value .Select-control .Select-value,
.config-panel-item__value .Select-control .Select-placeholder {
    line-height: 45px;
}

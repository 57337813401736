.analytics-logs-search {
    margin: 20px 0;
}

.analytics-logs-search__header {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
    font-size: 24px;
}
.analytics-logs-search__info {
    padding-left: 20px;
    font-size: 70%;
    color: #777;
}
.analytics-logs-search__icon {
    transition: transform 300ms ease-in-out;
    font-weight: bold;
    margin-left: auto;
}
.analytics-logs-search__icon--collapsed {
    transform: rotateZ(-180deg);
}

.analytics-logs-search__body {
    padding: 30px 0;
    border-bottom: 1px solid #e5e5e5;
}

.analytics-logs-search__button {
    margin-top: 30px;
}
.analytics-logs-search__invalid {
    padding-top: 10px;
    font-size: 70%;
    color: rgba(120, 0, 0, 0.48);
}

.analytics-logs-search .row {
    display: flex;
    align-items: center;
}

.analytics-logs-search label {
    margin-bottom: 0;
}

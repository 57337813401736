.job-processing-filter__container {
    margin-bottom: 30px;
}

.job-processing-filter__header {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
    font-size: 24px;
}

.job-processing-filter__body {
    margin-top: 10px;
}

.DateRangePickerInput, .DateRangePicker {
    width: 100%;
}

.job-processing-filter__clear {
    display: flex; 
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
}
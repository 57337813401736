.analytics-logs-results-details__label {
    width: 150px;
    font-weight: bold;
}

.analytics-logs-results-details__value {
    max-width: 600px;
    word-wrap: break-word;
}

.analytics-logs-results-details__pre {
    max-width: 700px;
    height: 300px;
}

.analytics-logs-results-details__response-code {
    border-bottom: 1px dotted;
    cursor: help;
}

.bulk-loads-chart {
    margin: 20px 0;
}
.bulk-loads-chart__rotate-labels > span {
    transform: rotate(30deg)!important;
    transform-origin: left;
}
.bulk-loads-chart__chart,
.bulk-loads-chart__chart-wrapper {
    overflow: visible!important;
}

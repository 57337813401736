.bulk-loads-search {
    margin: 20px 0;
}

.bulk-loads-search__header {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
    font-size: 24px;
}

.bulk-loads-search__icon {
    transition: transform 300ms ease-in-out;
    font-weight: bold;
    margin-left: auto;
}

.bulk-loads-search__icon--collapsed {
    transform: rotateZ(-90deg);
}

.bulk-loads-search__body {
    padding: 30px 0;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.bulk-loads-search .DateInput {
    width: auto;
}
.bulk-loads-search .DateInput,
.bulk-loads-search .DateInput_input {
    background: transparent;
}

.bulk-loads-search__column {
    width: 48%;
}

.bulk-loads-search__footer {
    margin-top: 20px;
    width: 100%;
}

.bulk-loads-search .row {
    display: flex;
    align-items: center;
}

.bulk-loads-search .select-component .Select-value {
    max-width: 270px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.bulk-loads-search .VirtualizedSelectOption {
    white-space: nowrap;
}

@media (max-width: 1200px) {
    .bulk-loads-search__column,
    .bulk-loads-search .DateInput_input {
        font-size: 16px;
    }
    .bulk-loads-search .select-component .Select-value {
        max-width: 210px;
    }
}

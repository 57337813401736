.email-subscriptions-export-type-list-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.email-subscriptions-export-type-list-item-content--icon {
  font-size: 1.1em;
}

.email-subscriptions-export-type-list-item-content--icon-disabled {
  opacity: 0.5;
}

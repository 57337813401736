.set-management-pricing {
    margin-top: 20px;
}

.set-management-pricing__title {
    font-size: 120%;
}

.set-management-pricing__select {
    width: 95%;
    margin-bottom: 10px;
}

.set-management-pricing__table {
    margin-top: 20px;
}

.set-management-pricing__item-bundle {
    width: 30%;
}

.set-management-pricing__item-product {
    width: 30%;
}

.set-management-pricing__item-reduction-type {
    width: 20%;
}

.set-management-pricing__item-reduction-value {
    width: 20%;
}

.set-management-product-edit__label {
    width: 40%;
    font-weight: bold;
}

.set-management-product-edit__value {
    padding-right: 0!important;
    width: 60%;
}

.set-management-product-edit__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.set-management-product-edit__save {
    margin-right: 10px;
}

.set-management-product-edit__invalid,
.set-management-product-edit__invalid .Select-control {
    border-color: red;
}

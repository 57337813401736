.code-list-item-container {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}

.code-list-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.code-list-item-drag-handle {
  width: 40px;
  height: 100%;
}

.code-list-item-header {
  height: 20px;
  width: 100%;
  text-align: right;
}

.code-list-item-config-value {
  padding-right: 10px;
}

.code-list-item-content-collapsible {
  padding-top: 10px;
}

.code-list-item-content {
  width: 100%;
}

.code-form-buttons {
  display: flex;
  justify-content: flex-end;
}

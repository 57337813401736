.financing-profiling-product__link {
    cursor: pointer;
}

.financing-profiling-product__modal-title {
    font-size: 22px;
    font-weight: bold;
}

.financing-profiling-product__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.financing-profiling-product__durations {
    padding-left: 20px;
    color: #777;
}

.workflow-tree-form-edit-node--actions--action .workflow-tree-form-edit-node--condition-list {
  padding-left: 2em;
}

.workflow-tree-form-edit-node--actions--action--label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.workflow-tree-form-edit-node--actions--action--label .mdi {
  font-size: 1.2em;
}

.set-management-product-item {
    font-size: 18px;
    border-bottom: 1px solid #d6d6d6;
}

.set-management-product-item:last-child {
    border-bottom: none;
}

.set-management-product-item .SortableHOCHandle {
    margin: 0;
    font-size: 26px;
}

.set-management-product-item__control span {
    cursor: pointer;
    font-size: 22px;
}

.set-management-product-item__control {
    text-align: center;
}

.set-management-product-item__drag {
    width: 40px;
    padding: 10px;
}

.set-management-product-item__modal-title {
    font-size: 22px;
    font-weight: bold;
}

.set-management-product-item__name {
    width: 35%;
}

.set-management-product-item__domain {
    width: 35%;
}

.set-management-product-item__unit-price {
    width: 30%;
}

.set-management-product-item__control {
    width: 1%;
}

.set-management-product-item__drag {
    width: 1%;
}

.forwards-form {
}

.forwards-form .form-group {
    margin-bottom: 5px;
}

.forwards-form .form-control {
    height: 36px;
}

.forwards-form__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.forwards-form__row.forwards-form__row--header {
    padding-bottom: 5px;
    font-weight: bold;
}

.forwards-form__row-period {
    width: 210px;
}

.forwards-form__row-rate {
    width: 210px;
}

.forwards-form__row-control {
    display: flex;
    justify-content: space-between;
    width: 90px;
    height: 36px;
}


.leadqualification-options__destinations-title {
    font-size: 120%;
}

.leadqualification-options__destination {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leadqualification-options__button-add {
    margin-top: 10px;
}

.leadqualification-options__button-add .button-component {
    width: auto;
}

.leadqualification-options__button-remove {
    cursor: pointer;
    width: 40px;
    text-align: right;
    font-size: 22px;
}

.leadqualification-options__triggers {
    margin-top: 20px;
}

.leadqualification-options__triggers-title {
    font-size: 120%;
}

.leadqualification-options__trigger {
    margin: 10px 0;
}

.leadqualification-options__trigger label {
    margin: 0;
    font-weight: normal;
}

.leadqualification-options__trigger input {
    margin: 0 10px 0 0;
}

.leadqualification-options__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.leadqualification-options__save {
    margin-right: 10px;
}

.leadqualification-options__invalid {
    border-color: red;
}

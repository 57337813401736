.holidays-mode-year {
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, minmax(100px, 1fr));
    box-sizing: border-box;
}
.holidays-mode-year__month {
    border: 1px solid #eee;
    margin-top: -1px;
    margin-left: -1px;
}

.job-processing__ellpsis {
    text-overflow: ellipsis;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
}

.job-processing-results-item__success {
    min-width: 60px;
}

.job-processing-results-item__createDate {
    min-width: 60px;
}

.job-processing-results-item__job {
    min-width: 200px;
}

.job-processing-results-item__customer {
    min-width: 200px;
}

.job-processing-results-item__message {
    min-width: 200px;
}

.job-processing-results-item__details {
    min-width: 70px;
}

.job-processing-results-item__history {
    min-width: 70px;
}

.icon-size {
    font-size: 30px;
}

.icon-button:hover {
    cursor: pointer;
}

.icon-disabled {
    color: lightgrey;
    cursor: default;
}
.confirmation-dialog {
    font-size: 16px;
}
.confirmation-dialog__backdrop {
    background-color: #fff;
}
.confirmation-dialog__backdrop.in {
    opacity: 0.7;
}
.confirmation-dialog .modal-dialog {
    width: 500px;
}
.confirmation-dialog .modal-header {
    font-size: 20px;
    padding: 20px;
}
.confirmation-dialog .close {
    font-size: 30px;
}

.confirmation-dialog .modal-body {
    margin-top: 10px;
    padding: 20px;
}

.confirmation-dialog .modal-footer {
    border-top: none;
    padding: 20px;
}
.confirmation-dialog .modal-footer .button-component {
    margin-left: 15px;
}

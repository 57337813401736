.external-link-details__section {
    margin-bottom: 20px;
}

.external-link-details__title {
    font-weight: bold;
}

.external-link-details__item {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.external-link-details__item-title {
    width: 12%;
    padding-left: 30px;
}

.external-link-details__item-input {
    width: 88%;
}

.external-link-details__item-select {
    width: 100%;
}

.external-link-details .form-control {
    font-size: 18px;
    padding: 8px;
    height: auto;
}

.external-link-details_required {
    color: #dd0000;
}

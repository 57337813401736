.holidays-mode-month__title {
    display: flex;
}
.holidays-mode-month__title-text {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
}
.holidays-mode-month__close {
    cursor: pointer;
    margin-left: 10px;
}

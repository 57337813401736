.bulk-loads-results-details__label {
    width: 80px;
    color: #888888;
}

.bulk-loads-results-details .table-component__head,
.bulk-loads-results-details .table-component__row {
    border-bottom: none;
    height: 25px;
}

.bulk-loads-results-details__details-table {
    margin-bottom: 20px;
}

.api-client-form {
    margin: 20px 0;
}

.api-client-form__body {
    padding: 30px 0;
    position: relative;
}

.api-client-form__section {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 8px;
    padding-bottom: 8px;
}
.api-client-form__label {
    min-width: 140px;
}
.api-client-form__input {
    flex-grow: 1;
    margin: 10px 0;
}
.api-client-form__input .form-group {
    margin-bottom: 0;
}
.api-client-form__input textarea {
    font-size: 18px;
    height: 100px;
}
.api-client-form__action {
    font-size: 22px;
    width: 25px;
    margin-left: 15px;
    cursor: pointer;
    text-align: center;
}
.api-client-form__headers {
    flex-grow: 1;
}


.api-client-form__invalid {
    padding-top: 10px;
    font-size: 70%;
    color: rgba(120, 0, 0, 0.48);
}

.api-client-form__footer {
    margin-top: 20px;
    width: 100%;
}

.api-client-form .select-component .Select-value {
    padding-right: 0!important;
    max-width: 810px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.api-client-form .VirtualizedSelectOption {
    white-space: nowrap;
}

.api-client-form__spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

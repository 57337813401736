.email-subscriptions-item .table-component__cell {
    padding: 2px 4px;
}

.email-subscriptions-item__action {
    cursor: pointer;
    font-size: 20px;
}

.email-subscriptions-item__modal-title {
    font-size: 20px;
    font-weight: bold;
}

.email-subscriptions-item__remove {
    cursor: pointer;
}

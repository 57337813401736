.ui-notes__table {
    margin-top: 30px;
}

.ui-notes__pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.ui-notes__button,
.ui-notes__button:hover {
    font-size: 1.5em;
    text-decoration: none;
    cursor: pointer;
}

.ui-notes__modal .modal-dialog {
    width: 80vw;
}

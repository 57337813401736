.holidays-calendar-day {
}
.holidays-calendar-day__title {
    font-size: 16px;
    font-weight: bold;
    padding: 5px 15px;
    background: #f8f8f8;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.holidays-calendar-day__create {
    color: #999;
}
.holidays-calendar-day__content {
    padding: 5px 15px;
    min-height: 40px;
}
.holidays-calendar-day__item {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 10px 0;
    line-height: 16px;
}
.holidays-calendar-day__link {
    cursor: pointer;
}

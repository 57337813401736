.set-management-product {
    margin-top: 20px;
}

.set-management-product__title {
    font-size: 120%;
}

.set-management-product__list {
    margin-top: 20px;
}

.set-management-product__name {
    width: 35%;
}

.set-management-product__domain {
    width: 35%;
}

.set-management-product__unit-price {
    width: 30%;
}

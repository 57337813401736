.module-overview--modules-row {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2em;
  height: 100%;
  gap: 1%
}

.module-overview--modules-row--col {
  padding-bottom: 2em;
  width: 32.6%;
}

.module-overview--categories-row {
  padding-bottom: 1em;
}

.module-overview--search-row .input-group-addon {
  background-color: #ffffff;
}

.module-overview--search-row .input-group-addon .mdi::before {
  font-size: 1.45em;
  vertical-align: text-bottom;
}

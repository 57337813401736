.lead-mass-mutation--table--body-row--detail {
  background-color: #f2f2f2;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.lead-mass-mutation--table--body-row--detail--row {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 2em;
  padding-right: 2em;
}

.lead-mass-mutation--table--body-row--detail--col-title {
  font-weight: 700;
  padding-bottom: 0.2em;
}

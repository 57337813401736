.module-overview--card {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2em;
  background-color: #ffffff;
  border: 0.01em solid rgba(0, 0, 0, 0.04);
  box-shadow: 13px 12px 18px -3px rgba(0, 0, 0, 0.05);
  height: 100%;
  border-bottom: 2px solid #008bc8;
}

.module-overview--card--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;
}

.module-overview--card--header--favourite {
  font-size: 1.3em;
  cursor: pointer;
}

.module-overview--card--header--title {
  font-size: 1.2em;
}

.module-overview--card--content {
  width: 100%;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;
  cursor: pointer;
}

.module-overview--card--content--image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.module-overview--card--content--description {
  padding-top: 2em;
  text-align: center;
}

.module-overview--card--content img {
  width: 60%;
  max-width: 60%;
}

.module-overview--card--footer {
  background-color: #1781e3;
  padding: 0.5em;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  width: 100%;
}

.bulk-loads-details-chart {
    margin: 20px 0;
}
.bulk-loads-details-chart__rotate-labels {
    top: 10px!important;
}

.bulk-loads-details-chart__rotate-labels > span {
    transform: rotate(30deg)!important;
    transform-origin: left;
}
.bulk-loads-details-chart__chart,
.bulk-loads-details-chart__chart-wrapper {
    overflow: visible!important;
}

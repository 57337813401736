.param-transfer-module {
  background-color: #ffffff;
  border: 0.01em solid rgba(0, 0, 0, 0.04);
  box-shadow: 13px 12px 18px -3px rgba(0, 0, 0, 0.05);
  margin-top: 2em;
  border-bottom: 1px solid #008bc8;
  padding: 2em;
  padding-bottom: 0.15em;
}
.param-transfer-module--row {
  display: flex;
  align-items: center;
}

.param-transfer-module--info {
  display: flex;
  align-items: center;
}

.param-transfer-module--info--image {
  display: flex;
  align-items: center;
  width: 10%;
}

.param-transfer-module--info--image img {
  width: 80%;
}

.param-transfer-module--info--service--name {
  font-weight: 700;
}

.param-transfer-module--sync {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.param-transfer-module--sync .mdi {
  font-size: 2em;
  cursor: pointer;
}

.param-transfer-module--info--exlapse {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  margin-top: 1.7em;
  border-top: 0.05em solid #eeeeee;
  color: #474b4e;
  cursor: pointer;
}

.param-transfer-module--sync--summary {
  text-align: center;
}

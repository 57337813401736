.lead-mass-mutation--table--body-row {
  border-bottom: 1px solid #e5e5e5;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.lead-mass-mutation--table--body-row--main {
  display: flex;
  align-items: center;
}

.lead-mass-mutation--table--body-row--main--first-col {
  display: flex;
  align-items: center;
}

.lead-mass-mutation--table--body-row--main--last-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lead-mass-mutation--table--body-row--main--last-col .mdi {
  font-size: 1.4em;
  cursor: pointer;
}

.lead-mass-mutation--table--body-row--pending {
  background-color: #e6e6ff;
}

.lead-mass-mutation--table--body-row--success {
  background-color: #f7ffe6;
}

.lead-mass-mutation--table--body-row--erroneous {
  background-color: #ffe6e6;
}

.lead-mass-mutation--table--body-row--expanded--error {
  background-color: #ffe6e6;
  text-align: center;
  width: 100%;
  color: #dd1122;
}

.lead-mass-mutation--table--body-row--expanded {

}

.lead-mass-mutation--table--body-row--expanded--collapsed {
  display: none !important;
  height: 0px;
}

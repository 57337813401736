.external-links-list {
    margin-top: 20px;
    border: none;
    background: transparent;
}
.external-links-list__header {
    display: flex;
    align-items: center;
    padding: 10px 45px;
    font-weight: bold;
}
.external-links-list__text {
    width: 350px
}
.external-links-list__actions {
    margin-top: 20px;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
}

.consultants__table {
    margin-top: 30px;
}

.consultants__pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.consultants__button,
.consultants__button:hover {
    font-size: 1.5em;
    text-decoration: none;
    cursor: pointer;
}

.consultants__modal .modal-dialog {
    width: 80vw;
}

.analytics-history-users {
    max-height: 500px;
    overflow: auto;
}
.analytics-history-users__text {
    margin-bottom: 30px;
}
.analytics-history-users__table {
    margin-top: 30px;
}
.analytics-history-users__table .table-component__cell {
    padding: 2px 4px;
}
.analytics-history-users__sortable {
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
}

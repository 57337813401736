.set-management-pricing-item {
    font-size: 18px;
    border-bottom: 1px solid #d6d6d6;
}

.set-management-pricing-item:last-child {
    border-bottom: none;
}

.set-management-pricing-item .SortableHOCHandle {
    margin: 0;
    font-size: 26px;
}

.set-management-pricing-item__modal-title {
    font-size: 22px;
    font-weight: bold;
}

.set-management-pricing-item__bundle {
    width: 30%;
}

.set-management-pricing-item__product {
    width: 30%;
}

.set-management-pricing-item__reduction-type {
    width: 20%;
}

.set-management-pricing-item__reduction-value {
    width: 20%;
}

.set-management-pricing-item__control {
    width: 1%;
    text-align: center;
}

.set-management-pricing-item__control span {
    cursor: pointer;
    font-size: 22px;
}

.workguide-component-size {
  display: flex;
}

.workguide-component-size--item {
  width: 20%;
}

.workguide-component-size--item input {
  width: 85%;
}

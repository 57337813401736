.bulk-loads-expand-content__body {
    padding: 20px 0;
}

.bulk-loads-expand-content__header {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
    font-size: 24px;
}

.bulk-loads-expand-content__icon {
    transition: transform 300ms ease-in-out;
    font-weight: bold;
    margin-left: auto;
}

.bulk-loads-expand-content__icon--collapsed {
    transform: rotateZ(-90deg);
}

.holidays-create-form__date {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.holidays-create-form__date-div {
    margin: 0 10px;
}
.holidays-create-form__date .SingleDatePickerInput__withBorder {
    border-radius: 4px;
}
.holidays-create-form__date .DateInput {
    background: transparent;
}
.holidays-create-form__date .DateInput_input {
    padding: 5px 12px;
    font-size: 14px;
    border: none;
}

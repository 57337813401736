.workflow-tree-board {
  position: relative;
  margin-top: 1em;
  height: 100%;
  min-height: 57vh;
  width: 100%;
  background-size: 40px 40px;
  background-image:
      linear-gradient(to right, lightgray 1px, transparent 1px),
      linear-gradient(to bottom, lightgray 1px, transparent 1px);
}

.workflow-tree-connections--connection {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.workflow-tree-connections--connection-actions {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  font-size: 1.5em;
}

.lead-mass-mutation--table--header-row {
  display: flex;
  align-items: center;
  font-weight: 700;
  border-top: 1px solid darkgray;
  border-bottom: 1px solid darkgray;
  background-color: #ffffff;
  padding-top: 0.5em;
  padding-bottom: 0.3em;
}

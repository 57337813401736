.toggle-component {
    display: flex;
}
.toggle-component.react-toggle {
    margin-left: 0;
    margin-right: 15px;
    height: 30px;
}
.toggle-component.react-toggle--checked {
    height: 30px;
}

.toggle-component .react-toggle-track {
    width: 68px;
    height: 5px;
    margin-top: 12px;
    background-color: #d6d6d6;
}
.toggle-component.react-toggle--checked .react-toggle-track {
    height: 30px;
    margin-top: 0;
    background-color: #19ab27;
}
.toggle-component.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #bebebe;
}
.toggle-component.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
}

.toggle-component .react-toggle-thumb {
    width: 28px;
    height: 28px;
}
.toggle-component.react-toggle--checked .react-toggle-thumb {
    left: 40px;
}
.toggle-component .react-toggle-track-check {
    color: #fff;
    line-height: 28px;
    padding-left: 8px;
    position: inherit;
    font-size: 24px;
}

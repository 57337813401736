.select-component .Select-control {
  padding-top: 5px;
  padding-bottom: 5px;
}
.select-component .Select-control .Select-value,
.select-component .Select-control .Select-placeholder {
  line-height: 45px;
}
.select-component.Select--multi .Select-value {
  line-height: 24px;
  margin-top: 4px;
}

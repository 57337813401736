.analytics-logs-results-item--selected {
    background: rgba(18, 159, 23, 0.11);
}

.analytics-logs-results-item__timestamp {
    max-width: 220px;
}

.analytics-logs-results-item__request {
    max-width: 600px;
    padding: 2px 4px;
    word-wrap: break-word;
}

.analytics-logs-results-item__action {
    text-align: right;
}

.analytics-logs-results-item .table-component__cell {
    padding: 2px 4px;
}

.analytics-logs-results-item__alert {
    padding: 10px;
    border: 1px solid #ebccd1;
    background-color: #f2dede;
    color: #a94442;
    border-radius: 4px;
}

.analytics-logs-results-item__icon-details,
.analytics-logs-results-item__icon-compare {
    cursor: pointer;
    border: 1px solid #000;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.analytics-logs-results-item__icon-details {
    font-style: italic;
}

.analytics-logs-results-item--disabled .analytics-logs-results-item__icon-details {
    pointer-events: none;
}

.analytics-logs-results-item__action.table-component__cell {
    padding: 2px 0;
}
.analytics-logs-results-item__action--compare.table-component__cell {
    padding-right: 4px;
}

.panel-default>.panel-heading {
  background-color:white;
 }

.code-mapping-detail--header {
  display: flex;
  align-items: center;
  font-size: 1.0em;
  background-color: white;
}

.code-mapping-detail--header--edit-icon {
  padding-left: 20px;
  font-size: 1.0em;
  cursor: pointer;
}

.code-mapping-detail--content-item {
  padding-bottom: 20px;
  font-size: 1.0em;
}

.code-mapping-detail--body :last-child {
  padding-bottom: 0px;
}

.code-mapping-detail--content-item-key {
  font-size: 1.0em;
}

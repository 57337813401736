.promotions-item {
    margin: 20px 0;
}
.promotions-item__body {
    text-align: center;
    width: 70%;
}
.promotions-item__body img {
    cursor: pointer;
}
.promotions-item__create {
    text-align: center;
    box-sizing: border-box;
    display: inline-block;
    width: 70%;
    height: 150px;
    border: 1px dashed;
    margin-top: 35px;
    padding-top: 30px;
    cursor: pointer;
}
.promotions-item__create span {
    font-size: 2.5em;
    line-height: 35px;
}

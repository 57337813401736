.replay-audit-log {
  padding-left: 10%;
  padding-right: 10%;
}

.replay-audit-log-make-it-so {
  height: 100px;
  font-weight: bolder;
  font-size: 2em;
}

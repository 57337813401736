.analytics-user-summary-export-form__export {
    margin-top: 10px;
}

.analytics-user-summary-export-form__indicator {
    display: inline-block;
    position: relative;
    width: 0;
    left: -20px;
}

.analytics-user-summary-export-form__spinner {
    display: inline-block;
    animation: spinAnimation 1s ease infinite;
}

.sort-indicator {
  display: inline-flex;
  width: 16px;
  height: 16px;

  justify-content: center;
  align-items: center;
}

.sort-indicator__arrow {
  display: inline-block;
  transition: transform 300ms ease;
  transform: rotateZ(-90deg);
  opacity: 0;
}
.sort-indicator--asc .sort-indicator__arrow,
.sort-indicator--desc .sort-indicator__arrow {
  opacity: 1;
}
.sort-indicator--desc .sort-indicator__arrow {
  transform: rotateZ(90deg);
}

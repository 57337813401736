.email-subscriptions-edit__footer {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.email-subscriptions-edit__save {
    margin-right: 10px;
}

.email-subscriptions-edit .row {
    display: flex;
    align-items: center;
}

.email-subscriptions-edit .select-component .Select-value {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.email-subscriptions-edit .VirtualizedSelectOption {
    white-space: nowrap;
}

.email-subscriptions-edit .form-control-feedback {
    display: none;
}

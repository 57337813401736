.set-management-bundle-item {
    font-size: 18px;
    border-bottom: 1px solid #d6d6d6;
}

.set-management-bundle-item:last-child {
    border-bottom: none;
}

.set-management-bundle-item .SortableHOCHandle {
    margin: 0;
    font-size: 26px;
}

.set-management-bundle-item__control span {
    cursor: pointer;
    font-size: 22px;
}

.set-management-bundle-item__control {
    text-align: center;
}

.set-management-bundle-item__drag {
    width: 40px;
    padding: 10px;
}

.set-management-bundle-item__modal-title {
    font-size: 22px;
    font-weight: bold;
}

.set-management-bundle-item__name {
    width: 30%;
}

.set-management-bundle-item__domain {
    width: 20%;
}

.set-management-bundle-item__bundle-price {
    width: 15%;
}

.set-management-bundle-item__bundle-price-reduced {
    width: 10%;
}
.set-management-bundle-item__bundle-price-youth {
    width: 40%;
}

.set-management-bundle-item__control {
    width: 1%;
}

.set-management-bundle-item__drag {
    width: 1%;
}

.checklist-module .nav-tabs {
    display: flex;
    margin-top: 30px;
    font-size: 16px;
    border: none;
    align-items: center;
    flex-wrap: wrap;
}
.checklist-module .nav-tabs li a {
    padding: 6px 14px;
    color: #888;
    cursor: pointer;
    border-width: 2px;
}
.checklist-module .nav-tabs li a:hover {
    border-width: 2px;
}
.checklist-module .nav-tabs li.active a {
    color: #777;
    border-color: #008bc8;
    border-width: 2px;
}

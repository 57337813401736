.code-mapping-form-mapping-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.code-mapping-form-buttons {
  width: 100%;
  text-align: right;
}

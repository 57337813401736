.analytics-history {
    margin-top: 30px;
}

.analytics-history__modal .modal-lg {
    width: 1100px;
}

.analytics-history__modal-header {
    display: flex;
    justify-content: space-between;
}

.analytics-history__day {
    padding: 5px 10px;
    cursor: pointer;
    height: 100px;
}

.analytics-history__day.analytics-history__day--empty,
.analytics-history__day.analytics-history__day--disabled {
    pointer-events: none;
    cursor: default;
}

.analytics-history__day.analytics-history__day--disabled {
    background: #f9f9f9;
}

.analytics-history__day-title {
    color: #aaa;
}

.analytics-history__day-requests {
    font-size: 30px;
    font-weight: 100;
    text-align: center;
}

.analytics-history__calendar {
    position: relative;
}

.analytics-history__spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

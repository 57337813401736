.replay-audit-log-record-list-item--errorneous {
  background-color: #ffb7b7;
}

.replay-audit-log-record-list-item--fulfilled {
  background-color: #8fe7a0;
}

.replay-audit-log-record-list-item--requesting {
  background-color: #c7d2ff;
}

.sortable-list.SortableHOCList {
    border: none;
    background: transparent;
}
.sortable-list.SortableHOCList .SortableHOCItem .input-group-addon {
    background: none;
    font-size: 22px;
    border-radius: 6px;
}
.sortable-list.SortableHOCList .SortableHOCItem .input-group-addon:first-child {
    border-right: 0;
    border-radius: 6px 0 0 6px;
}
.sortable-list.SortableHOCList .SortableHOCItem .input-group-addon:last-child {
    border-radius: 0 6px 6px 0;
}

.lead-mass-mutation--table {
  max-height: 800px;
}

.lead-mass-mutation--table table {
  width: 99.4%;
}

.lead-mass-mutation--table tr {
  border: unset;
}

.sortable-table__sortable {
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
}

.sortable-table__cell {
    vertical-align: baseline;
}

.modal-component {
    font-size: 16px;
}
.modal-component__backdrop {
    background-color: #fff;
}
.modal-component__backdrop.in {
    opacity: 0.9;
}
.modal-component .modal-component-header {
    font-size: 20px;
    padding: 20px;
}
.modal-component .close {
    font-size: 30px;
    outline: none;
}

.modal-component .modal-component-body {
    padding: 20px;
}

.modal-component .modal-component-footer {
    border-top: none;
    padding: 20px;
}
.modal-component .modal-component-footer .button-component {
    margin-left: 15px;
    outline: none;
}

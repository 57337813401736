.param-transfer-source-items--item {
  border-bottom: 0.05em solid #474b4e;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  color: #474b4e;
}

/* Sync */
.param-transfer-source-items--item--col-sync {
  font-size: 1.4em;
  text-align: center;
}

.param-transfer-source-items--item--col-sync--icon {
  cursor: pointer;
  padding-right: 0.5em;
}

.param-transfer-source-items--item--waiting .param-transfer-source-items--item--col-sync {
  color: #474b4e;
}

.param-transfer-source-items--item--pending .param-transfer-source-items--item--col-sync {
  color: #008bc8;
}

.param-transfer-source-items--item--fulfilled .param-transfer-source-items--item--col-sync {
  color: green;
}

.param-transfer-source-items--item--rejected .param-transfer-source-items--item--col-sync {
  color: red;
}

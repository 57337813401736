.email-subscriptions-table__pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.email-subscriptions-table__content {
    display: flex;
    flex-wrap: wrap;
}

.email-subscriptions-table__add {
    margin-top: 20px;
    margin-left: auto;
}

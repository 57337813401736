.date-create-form__date {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}


.date-picker-replay {
    width: 75%;
    height: 45px;
    margin-left: 10px;
    background-color: #fff;
    border-color: #d9d9d9 #ccc #b3b3b3;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #333;
}

.replay-dates-label {
    width: 25%;
}
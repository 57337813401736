.api-client-response-body__buttons {
    font-size: 24px;
    margin: 10px 0;
    text-align: right;
}

.api-client-response-body__button {
    margin-right: 10px;
    cursor: pointer;
}

.api-client-response-body__button--active {
    color: #008bc8;
}

.api-client-response-body__raw {
    max-width: 750px;
    height: 400px;
    font-family: monospace;
    font-size: 14px;
    background: #e5e5e5;
    overflow: auto;
    padding: 10px;
}

.api-client-response-body .react-json-view {
    font-size: 14px;
}

.api-client-response-body__pdf,
.api-client-response-body__img,
.api-client-response-body__content {
    max-width: 750px;
    overflow: auto;
}

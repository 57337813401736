.holidays__header {
    display: flex;
    align-items: center;
}
.holidays__title {
    margin-right: 10px;
}
.holidays__year {
    width: 100px;
}
.holidays__container {
    margin-top: 15px;
    position: relative;
    z-index: 1;
}
.holidays__selector {
    position: relative;
    z-index: 2;
}

.holidays__container .pagination {
    margin: 0 0 10px;
}

.workguide-action-configuration-service-call--mapping--entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.workguide-action-configuration-service-call--mapping--entry--label {
  padding-right: 2em;
  min-width: 10%;
}

.workguide-action-configuration-service-call--mapping--entry--value {
  width: 100%;
}

.workguide-action-configuration-service-call--mapping--entry--icon {
  font-size: 1.7em;
  padding-left: 2em;
}

.button-component {
    width: 185px;
    height: 46px;
    font-size: 18px;
    border-radius: 8px;
}
.button-component.btn-sm {
    width: 135px;
    height: 44px;
    border-radius: 4px;
}

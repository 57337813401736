.workflow-tree {
  width: 100%;
  height: 100%;
  min-height: 60vh;
}

.workflow-tree--toolbar {
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.5em;
  padding-right: 0.5em;
  left: 47%;
  width: 10%;
  background-color: #ffffff;
  border: 0.05em solid gray;
  border-bottom-left-radius: 0.2em;
  border-bottom-right-radius: 0.2em;
  font-size: 1.7em;
  font-weight: bolder;
}

.replay-audit-log-connection--row {
  padding-bottom: 10px;
}

.replay-audit-log-connection--submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.analytics-logs-results__alert {
    padding: 10px;
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
    border-radius: 4px;
    color: #8a6d3b;
    margin-bottom: 10px;
}

.analytics-logs-results__pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.analytics-logs-results__content {
    position: relative;
}

.analytics-logs-results__chart {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 4px;
    top: 10px;
    border: 1px solid #000;
}
